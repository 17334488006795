import React, { useEffect, useState, useRef } from 'react';
import { Collapse, Typography, Table, Button, Tag, Spin, Tooltip, Icon } from 'antd';
import { uniq, map, sum } from 'lodash';
import UserContext from 'auth/UserContext';
import { getLandingPageSkill, getSkillCategoryDetails } from 'api/skillsApi';
import { Link } from 'react-router-dom';
import SkillsFilters, { filterSkills } from './SkillsFilters';
import ButtonRow from 'components/common/ButtonRow';
import CardCollapse from 'components/common/collapse/CardCollapse';
import Stack from 'components/common/Stack';
import Toolbar from 'components/common/Toolbar';
import displayErrorNotification from 'utils/displayErrorNotification';
import { getSkillTreeRecursive } from 'utils/skillDetails';
import { useUser } from 'auth/UserHooks';
import { trackComponent } from 'telemetry/AppInsights';
import SavePreferencesButton from 'components/common/SavePreferencesButton';
import useLocalStorage from 'utils/useLocalStorage';
import { defaultSkillSortSettings, defaultSkillFilterSettings } from 'types/SkillPreferences';
import { useFeatures } from 'hooks/FeatureHooks';
import { getScoutTags } from 'api/scoutTagApi';
import { convertLandingsPageSkill } from 'utils/convert';
import ExperienceLevelModal from './ExperienceLevelModal';
import './InfoIcon.css';

const SkillsLandingPage = props => {
  const user = useUser();
  const features = useFeatures();
  const [skillTree, setSkillTree] = useState([]);
  const [filteredSkillTree, setFilteredSkillTree] = useState([]);
  const [skillCategories, setSkillCategories] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [filteredSkillCategories, setFilteredSkillCategories] = useState([]);
  const [scoutTags, setScoutTags] = useState([]);
  const [activePanels, setActivePanels] = useState([]);
  const [numSkills, setNumSkills] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterSettings, setFilterSettings] = useLocalStorage(
    'SkillsLandingPage_FilterSettings',
    defaultSkillFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage('SkillsLandingPage_SortSettings', defaultSkillSortSettings());
  const searchRef = useRef();
  const [expandOnLoad, setExpandOnLoad] = useState(
    features && features.isEnabled('ENABLE_SKILLS_EXPAND_ALL') ? false : true
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([getLandingPageSkill(), getSkillCategoryDetails(), getScoutTags()])
      .then(response => {
        let converted = convertLandingsPageSkill(response[0], response[1], response[2]);
        setSkillCategories(response[1]);
        let expandedRowsCreate = {};
        response[1].forEach(c => {
          expandedRowsCreate[c.name] = [];
        })
        setExpandedRows(expandedRowsCreate);

        setScoutTags(response[2]);
        let skillTree = getSkillTreeRecursive(converted);
        setSkillTree(skillTree);
        setNumSkills(countSelectableSkills(skillTree));
        filterSkills(
          {
            onSearch: _setFilteredSkills,
            skillTree: skillTree,
            settings: filterSettings,
            setSettings: setFilterSettings,
            scoutTags: response[2],
            skillCategories: response[1]
          },
          {},
          {
            searchTerm: filterSettings.search,
            selectedCategories: filterSettings.category,
            showInactive: filterSettings.inActive
          }
        );
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
        if (searchRef.current !== undefined && searchRef.current.input !== null) {
          searchRef.current.focus();
        }
        setExpandOnLoad(true);
      });
  }, []);

  const _setFilteredSkills = (_filteredSkillTree, _filteredSkillCategories) => {
    const panelIds = uniq(map(_filteredSkillTree, 'category'));
    if (expandOnLoad) {
      setActivePanels(panelIds);
    }
    setFilteredSkillTree(_filteredSkillTree);
    setFilteredSkillCategories(_filteredSkillCategories);
  };

  const toggleExpandAll = () => {
    const expanded = activePanels.length > 0;
    const panelIds = uniq(map(filteredSkillTree, 'category'));
    setActivePanels(expanded ? [] : panelIds);
  };

  const handlePanelChange = panels => {
    setActivePanels(panels);
  };

  const countSelectableSkills = records => {
    let count = 0;
    records.forEach(record => {
      if (record.children && record.children.length > 0) {
        count += countSelectableSkills(record.children);
      } else {
        count++;
      }
    });
    return count;
  };

  const countEmployees = record => {
    return record.children && record.children.length > 0
      ? record.employeeCount + sum(record.children.map(s => countEmployees(s)))
      : record.employeeCount;
  };

  const tagSortValue = record => {
    if (record.tags && record.tags.length > 0) {
      let total = 0;
      for (let i = 0; i < record.tags.length; i++) {
        if (i === 0) {
          total += record.tags[i].displayOrder;
        } else {
          total += record.tags[i].displayOrder * (i * 100);
        }
      }
      return total;
    }
    return 0;
  };

  const columns = [
    {
      title: 'Skill',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (text, record) =>
        record.children && record.children.length > 0 && !user.permissions.canUpdateSkillType ? (
          record.name + ' [' + record.id + ']'
        ) : (
          <Link to={`/skills/${record.id}`}>{record.name}</Link>
        ),
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Tags',
      key: 'tags',
      width: '150px',
      render: (text, record) =>
        record.tags.map(t => (
          <Tag key={'scouttag_' + t.id} color={t.inactive ? 'red' : ''}>
            {t.name}
          </Tag>
        )),
      sorter: (a, b) => tagSortValue(a) - tagSortValue(b)
    },
    {
      title: 'Child Skills',
      key: 'childskills',
      align: 'right',
      width: '90px',
      render: (text, record) =>
        record.children && record.children.length > 0
          ? record.children.length + sum(record.children.map(s => (s.children ? s.children.length : 0)))
          : ''
    },
    {
      title: 'Employees',
      key: 'employeeCount',
      width: '90px',
      align: 'right',
      render: (text, record) =>
        record.employeeCount > 0 && record.children && record.children.length > 0 && user.permissions.canUpdateSkillType
          ? '[' + record.employeeCount + '] ' + countEmployees(record)
          : countEmployees(record)
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      align: 'right',
      width: '90px',
      render: active => <Tag color={active ? 'green' : 'red'}>{active ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => a.active - b.active
    }
  ];

  function onChange(_pagination, _filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      const find = sortSettings.categories.find(o => {
        return o.category === sorter.column.category;
      });
      if (find === undefined) {
        sortSettings.categories.push({
          category: sorter.column.category,
          column: {
            key: sorter.columnKey,
            order: sorter.order
          }
        });
      } else {
        find.column.key = sorter.columnKey;
        find.column.order = sorter.order;
      }
      setSortSettings(sortSettings);
    }
  }

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <div>
            <Typography.Title level={1}>
              Skills
              <ButtonRow style={{ float: 'right' }}>
                {ctx.user.permissions.canCreateSkillType && (
                  <Button icon="solution" onClick={() => props.history.push('/skills/new')}>
                    Add Skill Type
                  </Button>
                )}
                <Button icon="plus" onClick={() => props.history.push('/employee/skills/new', { employeeId: user.id })}>
                  Add My Skill
                </Button>
                <SavePreferencesButton
                  keys={['SkillsLandingPage_FilterSettings', 'SkillsLandingPage_SortSettings']}
                  disabled={loading}
                />
              </ButtonRow>
            </Typography.Title>

            <Typography.Paragraph>
              The below skills inventory reflects a selection of skills submitted by each practice and organized into
              three primary categories: Management, Technology, and XD. As an employee, you can represent both the
              skills you have and those you're interested in learning by clicking "Add My Skill" above. Employees can
              also associate each skill they add to their profile with one or more of their past Credera projects,
              further enriching the information we have about past client work.
            </Typography.Paragraph>

            <Typography.Paragraph>
              New skills may be requested by emailing <a href="mailto:systems@credera.com">systems@credera.com</a> and
              will be reviewed periodically by stakeholders for addition to the system.
            </Typography.Paragraph>

            {features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') && (
              <div style={{display: "flex", alignItems: "center"}}>
              <Typography.Paragraph style={{marginBottom: '1rem'}}>
                Skill Experience is periodically synced with Projector. Projector Skill Experience of
                0 = None, 1 = Limited, 2 = Some, 3 = Substantial, 4 = Extensive, 5 = SME.
                <Icon type="info-circle" onClick={() => {setIsModalVisible(true)}} className="info-icon"/>
              </Typography.Paragraph>
              </div>
            )}

            <Toolbar>
              <SkillsFilters
                onSearch={_setFilteredSkills}
                skillTree={skillTree}
                searchRef={searchRef}
                settings={filterSettings}
                setSettings={setFilterSettings}
                disabled={loading}
                scoutTags={scoutTags}
                skillCategories={skillCategories}
              />
              <div>
                Showing {countSelectableSkills(filteredSkillTree)} of {numSkills} Skills
              </div>
            </Toolbar>

            <Toolbar style={{ background: 'transparent', padding: 0 }}>
              <Button icon={activePanels.length > 0 ? 'minus' : 'plus'} onClick={toggleExpandAll}>
                {(activePanels.length > 0 ? 'Collapse' : 'Expand') + ' All Categories'}
              </Button>
            </Toolbar>
          </div>

          <Spin spinning={loading}>
            <Collapse bordered={false} onChange={handlePanelChange} activeKey={activePanels}>
              {filteredSkillCategories.map(category => {
                const categorySkills = filteredSkillTree.filter(s => s.category === category.name);
                const categorySkillsCount = countSelectableSkills(categorySkills);

                const columnsSortSettings = columns.map(column => {
                  const find = sortSettings.categories.find(o => {
                    return o.category === category.name;
                  });
                  if (find !== undefined) {
                    if (column.key === find.column.key) {
                      return {
                        ...column,
                        defaultSortOrder: find.column.order,
                        category: category.name
                      };
                    }
                  } else {
                    if (column.key === sortSettings.default.key) {
                      return { ...column, defaultSortOrder: sortSettings.default.order, category: category.name };
                    }
                  }
                  return { ...column, category: category.name };
                });

                const categoryToggleExpandAllSkills = () => {
                  setExpandedRows({
                    ...expandedRows,
                    [category.name]: expandedRows[category.name].length > 0 ? [] : categorySkills.map(c => c.id)
                  });
                }

                const categorySetExpandedRows = (er) => {
                  setExpandedRows({
                    ...expandedRows,
                    [category.name]: er
                  });
                }

                return (
                  <CardCollapse.Panel
                    header={
                      category.description === '' ? (
                        category.name
                      ) : (
                        <Tooltip title={category.description}>{category.name}{' '}<Icon type="info-circle" /></Tooltip>
                      )
                    }
                    extra={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag>{`${categorySkillsCount} Skill${categorySkillsCount === 1 ? '' : 's'}`}</Tag>
                      </div>
                    }
                    key={category.name}
                  >
                    <Button
                      size="small"
                      icon={expandedRows[category.name].length > 0 ? 'minus' : 'plus'}
                      onClick={categoryToggleExpandAllSkills}
                      style={{ marginBottom: '10px' }}
                    >
                      {(expandedRows[category.name].length > 0 ? 'Collapse' : 'Expand') + ' All Skills'}
                    </Button>

                    <Table
                      rowKey="id"
                      style={{ width: '100%', background: 'none' }}
                      columns={columnsSortSettings}
                      dataSource={categorySkills}
                      pagination={false}
                      onChange={onChange}
                      expandedRowKeys={expandedRows[category.name]}
                      onExpandedRowsChange={categorySetExpandedRows}
                    />
                  </CardCollapse.Panel>
                );
              })}
            </Collapse>
          </Spin>
          <ExperienceLevelModal isModalVisible={isModalVisible} onCancel={() => {setIsModalVisible(false)}}/>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(SkillsLandingPage, 'Skills Landing Page');
