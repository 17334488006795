import React, { useState, useEffect } from "react";
import { Typography, Form, Button, Input, Select } from "antd";
import PropTypes from "prop-types";
import { pick } from "lodash";
import {
  getLandingPageSkill,
  getSkillCategories,
  createSkill,
  updateSkill,
  toggleSkillActivation
} from "api/skillsApi";
import ButtonRow from "components/common/ButtonRow";
import displayErrorNotification from "utils/displayErrorNotification";
import { getMidLevelSkills, getTopLevelSkills } from "utils/skillDetails";
import { useUser } from "../../../auth/UserHooks";
import SkillActivationModal from "./SkillActivationModal";
import { convertLandingsPageSkill } from "utils/convert";
import { getScoutTags } from "api/scoutTagApi";

const SkillForm = ({ skill, form, onSubmit, onCancel, isCreateMode }) => {
  const user = useUser();
  const [parentSkills, setParentSkills] = useState([]);
  const [filteredParentSkills, setFilteredParentSkills] = useState([]);
  const [skillCategories, setSkillCategories] = useState([]);
  const [scoutTags, setScoutTags] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const _toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([getLandingPageSkill(), getSkillCategories(), getScoutTags()])
      .then(response => {
        let converted = convertLandingsPageSkill(
          response[0],
          response[1],
          response[2]
        );
        setSkillCategories(response[1]);
        setScoutTags(response[2]);
        const parents = [
          ...getMidLevelSkills(converted),
          ...getTopLevelSkills(converted)
        ];
        const sortedParents = parents.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        setParentSkills(sortedParents);
        setFilteredParentSkills(sortedParents);
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
      });
  }, []);

  const handleActivation = () => {
    toggleSkillActivation(skill.id)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  const handleCategoryChange = value => {
    const filtered = parentSkills.filter(s => s.categoryId === value);
    setFilteredParentSkills(filtered);
    form.resetFields(["parentId"]);
  };

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;

      if (isCreateMode) {
        createSkill(values)
          .then(onSubmit)
          .catch(displayErrorNotification);
      } else {
        const updatedSkill = {
          ...pick(skill, ["id", "active"]),
          name: values.name,
          description: values.description,
          category: values.category,
          tags: values.tags,
          parentId: values.parentId || null
        };
        updateSkill(skill.id, updatedSkill)
          .then(onSubmit)
          .catch(displayErrorNotification);
      }
    });
  };

  const { getFieldDecorator } = form;
  const initialValues = !isCreateMode
    ? {
        ...skill,
        categoryId: skill.category ? skill.category.id : "",
        parentId: skill.parentSkill ? skill.parentSkill.id : "",
        tagIds: skill.tags ? skill.tags.map(t => t.id) : ""
      }
    : {};

  return (
    <Form
      layout="vertical"
      onSubmit={handleSubmit}
      style={{ maxWidth: "40em" }}
    >
      <Typography.Title>
        {isCreateMode ? "Add New" : "Edit"} Skill Type
      </Typography.Title>

      <Form.Item label="Category" required>
        {getFieldDecorator("category", {
          initialValue: initialValues.categoryId,
          rules: [{ required: true, message: "Category is a required field" }]
        })(
          <Select
            showArrow
            disabled={loading}
            placeholder="Category"
            onChange={handleCategoryChange}
          >
            {skillCategories.map(c => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Parent">
        {getFieldDecorator("parentId", {
          initialValue: initialValues.parentId
        })(
          <Select
            showSearch
            allowClear
            placeholder="Select a Parent Skill"
            optionFilterProp="children"
            disabled={
              loading ||
              (!isCreateMode && parentSkills.map(s => s.id).includes(skill.id)) ||
              form.getFieldValue("category") === undefined
            }
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {filteredParentSkills.map(s => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Skill Name" required>
        {getFieldDecorator("name", {
          initialValue: initialValues.name,
          rules: [
            { required: true, message: "Name is a required field" },
            // {max: 100, message: "Name must be fewer than 100 characters"},
            {
              validator: (rule, resp) => {
                var parentId = form.getFieldValue("parentId");
                var length = 100;
                if (parentId === undefined) {
                  var category = form.getFieldValue("category");
                  if (category !== undefined) {
                    var find = skillCategories.find(c => c.id === category);
                    if (find === undefined) {
                      return Promise.reject("Please select a valid category");
                    } else {
                      length = 100 - find.name.length - 3;
                    }
                  } else {
                    return Promise.reject("Please select a category first");
                  }
                }
                if (resp.endsWith(" ")) {
                  return Promise.reject("Name cannot end with a space");
                }
                if (resp.length > length) {
                  return Promise.reject(
                    `Name must be fewer than ${length} characters` +
                      (parentId !== undefined
                        ? ""
                        : ", since the category will be prefixed when sending parent skill to Projector.")
                  );
                }
                return Promise.resolve();
              }
            }
          ]
        })(<Input disabled={loading} />)}
      </Form.Item>

      <Form.Item label="Description">
        {getFieldDecorator("description", {
          initialValue: initialValues.description,
          rules: [
            {
              max: 255,
              message: "Description must be fewer than 255 characters"
            }
          ]
        })(<Input disabled={loading} />)}
      </Form.Item>

      <Form.Item label="Tags">
        {getFieldDecorator("tags", {
          initialValue: initialValues.tagIds
        })(
          <Select mode="multiple" placeholdder="Tags" disabled={loading}>
            {scoutTags.map(st => (
              <Select.Option key={st.id} value={st.id}>
                {st.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <ButtonRow style={{ marginTop: "1em" }}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
        {!isCreateMode && user.permissions.canDeleteSkillType && (
          <Button
            onClick={_toggleModalVisibility}
            disabled={skill.childSkills && skill.childSkills.length > 0}
          >
            {skill && skill.active ? "Deactivate" : "Activate"}
          </Button>
        )}
      </ButtonRow>

      {!isCreateMode && (
        <SkillActivationModal
          action={skill.active ? "Deactivate" : "Activate"}
          isModalVisible={isModalVisible}
          skill={skill}
          disabled={loading}
          closeModal={_toggleModalVisibility}
          onSubmit={handleActivation}
        />
      )}
    </Form>
  );
};

SkillForm.propTypes = {
  skill: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: "skill_form" })(SkillForm);
