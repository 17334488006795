import React from 'react';
import { Modal } from 'antd';
import { Typography } from 'antd';


const ExperienceLevelModal = props => {
  return (
    <Modal title="Experience Levels" visible={props.isModalVisible} onCancel={props.onCancel} footer={null}>
      <Typography.Paragraph><b>0 - None</b> - No experience (either do not select the skill, or select and set to “None”)</Typography.Paragraph>
      <Typography.Paragraph><b>1 - Limited</b> - Very limited exposure. Touched on this area before - perhaps interfaced with it when working
        on 1 or 2 projects. Have a basic awareness but little or no direct delivery experience. </Typography.Paragraph>
      <Typography.Paragraph><b>2 - Some</b> - Some delivery experience. Experience of direct delivery on 1 or 2 projects,
        or cumulative experience of 6 to 12 months.</Typography.Paragraph>
      <Typography.Paragraph><b>3 - Substantial</b> - Substantial Experience. Experience of direct delivery on 2 or 3 projects,
        or cumulative experience of 1 to 3 years. Working towards certification where appropriate.</Typography.Paragraph>
      <Typography.Paragraph><b>4 - Extensive</b> - Extensive Experience. Experience spans over 4 projects or cumulative
        experience of 3 to 5 years. Holds relevant certifications where appropriate. </Typography.Paragraph>
      <Typography.Paragraph><b>5 - SME</b> - Subject Matter Expert. Experience spans over 5 projects or cumulative experience of over 5 years.
        Holds relevant certifications where appropriate. Regarded by colleagues and clients as the 'go-to' person,
        able to challenge client SMEs/C level execs/etc.</Typography.Paragraph>
    </Modal>
  )
}

export default ExperienceLevelModal;