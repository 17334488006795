export const getDayPicker = () => {
  const DayPickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker'
  };
  return DayPickerStrings;
};

export const experienceLevels = ['NONE', 'LIMITED', 'SOME', 'SUBSTANTIAL', 'EXTENSIVE', 'SME'];

export const prExperienceLevels = ['NONE', 'LIMITED', 'SOME', 'SUBSTANTIAL', 'EXTENSIVE', 'SME'];

export const skillDurations = ['N/A', '0-6 months', '6-12 months', '1-3 years', '3-5 years', '5+ years', '10+ years'];
