import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Form,
  Select,
  TreeSelect,
  Button,
  Typography,
  Table,
  Modal,
  DatePicker,
  Icon,
  Radio
} from 'antd';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';
import { getProjects } from 'api/employeeApi';
import displayErrorNotification from 'utils/displayErrorNotification';
import ButtonRow from 'components/common/ButtonRow';
import { prExperienceLevels, skillDurations as roleTypeDurations } from 'components/constants/Constants';
import enumToDisplayText from 'utils/enumToDisplayText';
import formatDate from 'utils/formatDate';
import { useUser } from 'auth/UserHooks';
import {
  addRoleTypeToEmployee,
  getAllRoleTypes,
  getRoleTypesByEmployeeId,
  updateEmployeeRoleType,
  deleteRoleTypeExperienceById,
  deleteEmployeeRoleType,
  addRoleTypeExperience,
  getRoleTypeFamilies
} from 'api/RoleTypeApi';
import { getRoleTypeFamilyTree } from 'utils/RoleTypeDetails';
import TreeSelectRecursive from 'components/skills/skill/TreeSelectRecursive';
import { useFeatures } from 'hooks/FeatureHooks';
import ExperienceLevelModal from 'components/skills/skill/ExperienceLevelModal';

const EmployeeRoleTypeForm = ({
  employeeId,
  roleTypeExperiences = [],
  employeeRoleType,
  form,
  onSubmit,
  onCancel,
  isCreateMode
}) => {
  const projectIds = roleTypeExperiences.map(pre => pre.project.id);
  const [roleTypes, setRoleTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState(projectIds);
  const [filteredDurations, setFilteredDurations] = useState(roleTypeDurations);
  const user = useUser();
  const features = useFeatures();
  const isMe = user.id === employeeId;
  const isCoach = user.coachees.includes(employeeId);
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!isCreateMode && !employeeRoleType) throw new Error('employeeRoleType required when isCreateMode is false');
  if (isCreateMode && !employeeId) throw new Error('employeeId required when isCreateMode is true');

  function _processResponse(response) {
    let loadroleTypes;
    if (isCreateMode) {
      const excludeId = response[3].map(rt => rt.roleType.id);
      loadroleTypes = response[1].filter(rt => !excludeId.includes(rt.id));
    } else {
      loadroleTypes = response[1];
    }
    setRoleTypes(getRoleTypeFamilyTree(response[0], loadroleTypes));
    setProjects(response[2].filter(p => p.hoursPerWeek > 0));
  }

  useEffect(() => {
    if (isCreateMode) {
      Promise.all([
        getRoleTypeFamilies(),
        getAllRoleTypes(),
        getProjects(employeeId),
        getRoleTypesByEmployeeId(employeeId)
      ])
        .then(response => {
          _processResponse(response);
        })
        .catch(displayErrorNotification);
    } else {
      Promise.all([getRoleTypeFamilies(), getAllRoleTypes(), getProjects(employeeId)])
        .then(response => {
          _processResponse(response);
        })
        .catch(displayErrorNotification);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) return;

      if (isCreateMode) {
        const data = {
          ...values,
          lastUsedDate: values['lastUsedDate']
            ? values['lastUsedDate'].startOf('month').format('YYYY-MM-DD')
            : undefined,
          interest: !!values.interest,
          employeeId
        };

        addRoleTypeToEmployee(data)
          .then(response => {
            updateRoleTypeExperiences(response);
          })
          .catch(displayErrorNotification);
      } else {
        const data = {
          ...values,
          lastUsedDate: values['lastUsedDate']
            ? values['lastUsedDate'].startOf('month').format('YYYY-MM-DD')
            : undefined,
          employeeId,
          employeeRoleTypeId: employeeRoleType.employeeRoleTypeId
        };
        updateEmployeeRoleType(data)
          .then(response => {
            updateRoleTypeExperiences(response);
          })
          .catch(displayErrorNotification);
      }
    });
  };

  const updateRoleTypeExperiences = epr => {
    const projectIdsToAdd = selectedProjectIds.filter(i => !projectIds.includes(i));
    const roleTypeExperienceIdsToRemove = roleTypeExperiences
      .filter(pre => !selectedProjectIds.includes(pre.project.id))
      .map(pre => pre.roleTypeExperienceId);

    const apiRequests = [];

    forEach(projectIdsToAdd, projectId => {
      const data = {
        employeeRoleTypeId: epr.employeeRoleTypeId,
        projectId: projectId
      };
      apiRequests.push(addRoleTypeExperience(data));
    });

    forEach(roleTypeExperienceIdsToRemove, id => {
      apiRequests.push(deleteRoleTypeExperienceById(id));
    });

    Promise.all(apiRequests)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  const handleRemoveRoleType = e => {
    e.stopPropagation();
    Modal.confirm({
      icon: 'exclamation-circle',
      title: 'Are you sure you want to remove this project role?',
      cancelText: 'Cancel',
      maskClosable: true,

      onOk: removeRoleType,
      onCancel: () => {}
    });
  };

  const handlePRExperienceLevelChange = value => {
    form.resetFields(['duration']);
    const durations = roleTypeDurations.filter(d => {
      if (value === 'NONE') {
        return d === 'N/A';
      } else {
        return d !== 'N/A';
      }
    });
    setFilteredDurations(durations);

    if (value === 'NONE') {
      form.setFieldsValue({ duration: 'N/A', interest: true });
    }
  };

  const removeRoleType = () => {
    const apiRequests = [];
    const roleTypeExperienceIds = roleTypeExperiences.map(pe => pe.roleTypeExperienceId);
    forEach(roleTypeExperienceIds, id => {
      apiRequests.push(deleteRoleTypeExperienceById(id));
    });

    Promise.all(apiRequests)
      .then(() => {
        deleteEmployeeRoleType(employeeRoleType.employeeRoleTypeId)
          .then(onSubmit)
          .catch(displayErrorNotification);
      })
      .catch(displayErrorNotification);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedProjectIds(selectedRowKeys);
  };

  const columns = [
    {
      title: 'Client',
      key: 'client',
      dataIndex: 'project.client.name'
    },
    {
      title: 'Project',
      dataIndex: 'project.name'
    },
    {
      title: 'Practice',
      dataIndex: 'project.practice'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: formatDate,
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: formatDate,
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1)
    }
  ];

  const { getFieldDecorator } = form;

  const initialValues = isCreateMode
    ? {}
    : {
        ...employeeRoleType,
        roleTypeId: employeeRoleType.roleType ? employeeRoleType.roleType.id : null,
        lastUsedDate: employeeRoleType.lastUsedDate ? moment(employeeRoleType.lastUsedDate) : null
      };

  return (
    <>
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Typography.Title>{isCreateMode ? 'Add' : 'Edit'} Role Type</Typography.Title>

      <Typography.Paragraph>
        To indicate interest in filling a project role that you don't currently have experience in, select 
        "None" in the Experience Level drop-down menu.
      </Typography.Paragraph>

      <Typography.Title level={3}>Role Type Details</Typography.Title>

      <div style={{ maxWidth: '40em' }}>
        <Form.Item label="Role Type" required>
          {getFieldDecorator('roleTypeId', {
            initialValue: initialValues.roleTypeId,
            rules: [{ required: true, message: 'Role Type is a required field' }]
          })(
            <TreeSelect
              showSearch
              disabled={!isCreateMode}
              placeholder="Select a Role Type"
              treeNodeFilterProp="title"
              dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
            >
              {roleTypes.map(pr => TreeSelectRecursive(pr, 'contains'))}
            </TreeSelect>
          )}
        </Form.Item>

        <Form.Item
          label={
            features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') ? (
              <>
              Experience Level
              <Icon type="info-circle" onClick={() => {setIsModalVisible(true)}} className="info-icon" style={{paddingTop:"3px"}}/>
              </>
            ) : (
              'Experience Level'
            )
          }
          required 
        >
          {getFieldDecorator('experienceLevel', {
            initialValue: initialValues.experienceLevel,
            rules: [{ required: true, message: 'Experience Level is a required field' }]
          })(
            <Select placeholder="Select an Experience Level" onChange={handlePRExperienceLevelChange}>
              {prExperienceLevels.map(e => (
                <Select.Option key={e} value={e}>
                  {enumToDisplayText(e)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Duration of Experience" required>
          {getFieldDecorator('duration', {
            initialValue: initialValues.duration,
            rules: [{ required: true, message: 'Duration is a required field' }]
          })(
            <Select
              placeholder="Select a Duration"
              disabled={form.getFieldValue('experienceLevel') === 'NONE'}
            >
              {filteredDurations.map(d => (
                <Select.Option key={d} value={d}>
                  {d}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {form.getFieldValue('experienceLevel') !== 'NONE' ? (
          <Form.Item label="Last Used Date" required>
            {getFieldDecorator('lastUsedDate', {
              initialValue: initialValues.lastUsedDate,
              rules: [{ required: true, message: 'Last Used Date is a required field' }]
            })(<DatePicker.MonthPicker format={'MMM YYYY'} disabledDate={d => !d || d.isAfter(moment())} />)}
          </Form.Item>
        ) : null}

        {form.getFieldValue('experienceLevel') !== 'NONE' ? (
          <Form.Item label="Are you interested in using this in the future?" required>
            {getFieldDecorator('interest', {
              initialValue: initialValues.interest,
              valuePropName: 'checked',
              rules: [{ required: true, message: 'Please select an option' }]
            })(
              <Radio.Group defaultValue={initialValues.interest}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        ) : (
          <Form.Item label="Are you interested in using this in the future?!" required>
            {getFieldDecorator('interest', {
              initialValue: true,
              valuePropName: 'checked',
            })(
              <>
                <Radio defaultChecked disabled value={true}>Yes</Radio>
                <Radio disabled value={false}>No</Radio>
              </>
            )}
          </Form.Item>
        )}

      </div>

      {form.getFieldValue('experienceLevel') !== 'NONE' ? (
        <Typography.Title level={3} style={{ marginTop: '1em' }}>
          Role Type Experience
        </Typography.Title>
      ) : null}

      {form.getFieldValue('experienceLevel') !== 'NONE' ? (
        <Table
          rowKey={record => record.project.id}
          style={{ width: '100%' }}
          dataSource={projects}
          columns={columns}
          pagination={false}
          rowSelection={{
            selectedRowKeys: selectedProjectIds,
            onChange: onSelectChange
          }}
        />
      ) : null}

      <ButtonRow style={{ marginTop: '2em' }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
        {!isCreateMode &&
          ((isMe && user.permissions.canDeleteOwnRoleType) ||
            (isCoach && user.permissions.canDeleteCoacheeRoleType) ||
            user.permissions.canDeleteRoleType) && <Button onClick={handleRemoveRoleType}>Remove Role Type</Button>}
      </ButtonRow>
    </Form>
    <ExperienceLevelModal isModalVisible={isModalVisible} onCancel={() => {setIsModalVisible(false)}}/>
    </>
  );
};

EmployeeRoleTypeForm.propTypes = {
  employeeId: PropTypes.number,
  roleTypeExperiences: PropTypes.array,
  employeeRoleType: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: 'employee_role_type_form' })(EmployeeRoleTypeForm);
